import { Link } from "react-router-dom";

export default function MenuOverlay({ menuOpen, setMenuOpen, setVideoOpen }) {

  let className = 'menu-nav';


  if (menuOpen) {
    className += ' visible';
  }

  return (
    <div className={className}>
      <nav>
        <Link to="/music" onClick={() => { setMenuOpen(false); setVideoOpen(false); }}>MUSIC</Link>
        <Link to="/tour" onClick={() => { setMenuOpen(false); setVideoOpen(false); }}>TOUR</Link>
        <Link to="/store" onClick={() => { setMenuOpen(false); setVideoOpen(false); }}>STORE</Link>
        <Link to="/video" onClick={() => { setMenuOpen(false); setVideoOpen(true); }}>VIDEO</Link>
        <Link to="/contact" onClick={() => { setMenuOpen(false); setVideoOpen(false); }}>CONTACT</Link>
      </nav>
    </div>
  );
}