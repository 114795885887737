import Banner from "../components/Banner";
import AlbumCover from "../components/AlbumCover";
import AlbumInfo from "../components/AlbumInfo";
import FreeLove from "../assets/FreeLove.jpg";
import NoRulesSandy from "../assets/NRS.jpg";
import TenYear from "../assets/10Year.jpg";
import WhatNow from "../assets/WhatNow.jpg";
import SelfTitled from "../assets/SelfTitled.jpg";
import { getAlbums } from "../albumData";
import { useEffect, useRef } from "react";
import { isMobile } from 'react-device-detect';


const scrollToRef = (ref) => window.scrollTo({
  top: ref.current.offsetTop,
  left: 0,
  behavior: 'smooth'
})


export default function Music() {

  let albums = getAlbums();


  const myRef = useRef(null)
  useEffect(() => {
    const timer = setTimeout(() => scrollToRef(myRef), 1000);
    return () => clearTimeout(timer);
  }, []);


  return (
    <div>
      <Banner page="music" />
      <div ref={isMobile ? null : myRef} className="music-grid scroll-container">
        <div className="album-container">
          <AlbumCover src={TenYear} />
          <AlbumInfo name={albums[0].name} streamLink="https://ffm.to/SE10" downloadLink="https://sylvanesso.bandcamp.com/album/sylvan-esso-10-year-anniversary-edition" />
        </div>
        <div className="album-container reverse">
          <AlbumInfo name={albums[1].name} streamLink="https://lnk.sylvanesso.com/NoRulesSandy" downloadLink="https://sylvanesso.bandcamp.com/" />
          <AlbumCover src={NoRulesSandy} />
        </div>
        <div className="album-container">
          <AlbumCover src={FreeLove} />
          <AlbumInfo name={albums[2].name} streamLink="https://found.ee/SE_FreeLove" downloadLink="https://sylvanesso.bandcamp.com/album/free-love" />
        </div>
        <div className="album-container reverse">
          <AlbumInfo name={albums[3].name} streamLink="https://found.ee/SE_WhatNow" downloadLink="https://sylvanesso.bandcamp.com/album/what-now" />
          <AlbumCover src={WhatNow} />
        </div>
        <div className="album-container">
          <AlbumCover src={SelfTitled} />
          <AlbumInfo name={albums[4].name} streamLink="https://lnk.to/SylvanEsso_SE" downloadLink="https://sylvanesso.bandcamp.com/album/sylvan-esso" />
        </div>
      </div>
    </div>
  );
}