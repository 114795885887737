let albums = [
  {
    name: "Sylvan Esso (10 Year Anniversary Edition) (2024)",
    tracks: [
      {
        trackName: "01. Hey Mami",
        length: "3:20",
      },
      {
        trackName: "02. Dreamy Bruises",
        length: "4:08",
      },
      {
        trackName: "03. Could I Be",
        length: "4:39",
      },
      {
        trackName: "04. Wolf",
        length: "3:15",
      },
      {
        trackName: "05. Dress",
        length: "3:49",
      },
      {
        trackName: "06. H.S.K.T.",
        length: "4:17",
      },
      {
        trackName: "07. Coffee",
        length: "4:28",
      },
      {
        trackName: "08. Uncatena",
        length: "4:15",
      },
      {
        trackName: "09. Play It Right",
        length: "3:04",
      },
      {
        trackName: "10. Come Down",
        length: "2:57",
      },
      {
        trackName: "11. Hey Mami (Rick Wade Remix)",
        length: "6:58",
      },
      {
        trackName: "12. H.S.K.T. (Dntel Remix)",
        length: "4:14",
      },
      {
        trackName: "13. Coffee (Helado Negro Remix)",
        length: "4:19",
      },
      {
        trackName: "14. Hey Mami (Charles Spearin Remix)",
        length: "5:32",
      },
      {
        trackName: "15. H.S.K.T. (Hercules And Love Affair Remix)",
        length: "6:34",
      },
      {
        trackName: "16. Coffee (J Rocc Remix)",
        length: "4:49",
      },
    ]
  },
  {
    name: "No Rules Sandy (2022)",
    tracks: [
      {
        trackName: "01. Moving",
        length: "2:08",
      },
      {
        trackName: "02. Look At Me",
        length: "2:19",
      },
      {
        trackName: "03. (Bad Fills)",
        length: "0:19",
      },
      {
        trackName: "04. Echo Party",
        length: "4:16",
      },
      {
        trackName: "05. How Did You Know",
        length: "4:02",
      },
      {
        trackName: "06. (Betty's, May 4, 2022)",
        length: "0:09",
      },
      {
        trackName: "07. Didn't Care",
        length: "2:45",
      },
      {
        trackName: "08. (Vegas // Dad)",
        length: "0:24",
      },
      {
        trackName: "09. Your Reality",
        length: "2:15",
      },
      {
        trackName: "10. (#1vm)",
        length: "0:18",
      },
      {
        trackName: "11. Cloud Walker",
        length: "2:37",
      },
      {
        trackName: "12. Sunburn",
        length: "3:01",
      },
      {
        trackName: "13. (?)",
        length: "0:13",
      },
      {
        trackName: "14. Alarm",
        length: "4:00",
      },
      {
        trackName: "15. (No Rules Sandy)",
        length: "0:20",
      },
      {
        trackName: "16. Coming Back to You",
        length: "4:59",
      },
    ]
  },
  {
    name: "Free Love (2020)",
    tracks: [
      {
        trackName: "01. What If",
        length: "1:25",
      },
      {
        trackName: "02. Ring",
        length: "2:37",
      },
      {
        trackName: "03. Ferris Wheel",
        length: "2:57",
      },
      {
        trackName: "04. Train",
        length: "2:58",
      },
      {
        trackName: "05. Numb",
        length: "4:25",
      },
      {
        trackName: "06. Free",
        length: "2:36",
      },
      {
        trackName: "07. Frequency",
        length: "2:50",
      },
      {
        trackName: "08. Runaway",
        length: "3:01",
      },
      {
        trackName: "09. Rooftop Dancing",
        length: "3:03",
      },
      {
        trackName: "10. Make It Easy",
        length: "3:19",
      },
    ]
  },
  {
    name: "What Now (2017)",
    tracks: [
      {
        trackName: "01. Sound",
        length: "2:32",
      },
      {
        trackName: "02. The Glow",
        length: "2:58",
      },
      {
        trackName: "03. Die Young",
        length: "3:30",
      },
      {
        trackName: "04. Radio",
        length: "3:32",
      },
      {
        trackName: "05. Kick Jump Twist",
        length: "4:23",
      },
      {
        trackName: "06. Song",
        length: "3:27",
      },
      {
        trackName: "07. Just Dancing",
        length: "4:28",
      },
      {
        trackName: "08. Signal",
        length: "3:29",
      },
      {
        trackName: "09. Slack Jaw",
        length: "3:05",
      },
      {
        trackName: "10. Rewind",
        length: "4:39",
      },
    ]
  },
  {
    name: "Sylvan Esso (2014)",
    tracks: [
      {
        trackName: "01. Hey Mami",
        length: "3:20",
      },
      {
        trackName: "02. Dreamy Bruises",
        length: "4:08",
      },
      {
        trackName: "03. Could I Be",
        length: "4:39",
      },
      {
        trackName: "04. Wolf",
        length: "3:15",
      },
      {
        trackName: "05. Dress",
        length: "3:49",
      },
      {
        trackName: "06. H.S.K.T.",
        length: "4:17",
      },
      {
        trackName: "07. Coffee",
        length: "4:28",
      },
      {
        trackName: "08. Uncatena",
        length: "4:15",
      },
      {
        trackName: "09. Play It Right",
        length: "3:04",
      },
      {
        trackName: "10. Come Down",
        length: "2:57",
      },
    ]
  },
];

export function getAlbums() {
  return albums;
}