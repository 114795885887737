import Banner from "../components/Banner";
import StoreCollection from "../components/StoreCollection";
import { useEffect, useRef, useState } from "react";
import { isMobile } from 'react-device-detect';

const scrollToRef = (ref) => window.scrollTo({
  top: ref.current.offsetTop,
  left: 0,
  behavior: 'smooth'
})


export default function Store() {

  const myRef = useRef(null)
  useEffect(() => {
    let initialHeight = document.body.scrollHeight + 20;
    //const heightGetter = setTimeout(() => {initialHeight = document.body.scrollHeight + 20}, 100)
    const timer = setInterval(() => { if (document.body.scrollHeight > initialHeight) { scrollToRef(myRef); clearInterval(timer) } }, 1000);

    return (() => {
      //clearTimeout(heightGetter); 
      clearInterval(timer)
    })
  });

  return (
    <div>
      <Banner page="store" />
      <div className="scroll-container" ref={isMobile ? null : myRef}>
        <StoreCollection />
      </div>
    </div>
  );
}