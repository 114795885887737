
import { useEffect } from "react";
import { loadCollection } from "../util/loadCollection";

export default function StoreCollection({}) {
  useEffect(() => {
    loadCollection()
  })

  return (
    <div>
      <div id='collection-component-1686845033952'></div>
    </div>
  );
}