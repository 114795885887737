import "./plyr.css";
import "./plyr2.css";
import Plyr from "plyr-react";

export default function VideoPlayer({ setBannerEnabled, videoSource }) {
  const videoSrc = {
    type: "video",
    sources: [
      {
        src: videoSource,
        provider: "youtube"
      }
    ]
  };

  return (
    <div class="plyr__video-embed" id="player" onClick={() => setBannerEnabled(false)}>
      <Plyr source={videoSrc} />
    </div>
  );
}

