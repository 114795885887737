import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import Music from './screens/Music';
import Tour from './screens/Tour';
import Home from './screens/Home';
import Store from './screens/Store';
import Video from './screens/Video';
import Contact from './screens/Contact';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/" element={<Home />} />
        <Route path="music" element={<Music />} />
        <Route path="tour" element={<Tour />} />
        <Route path="store" element={<Store />} />
        <Route path="video" element={<Video />} />
        <Route path="contact" element={<Contact />} />
        <Route
          path="*"
          element={
            <main style={{
              padding: "1rem"
            }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Route>
    </Routes>
  </BrowserRouter >
);
