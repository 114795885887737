import './App.css';
import MenuButton from './components/MenuButton';
import MenuOverlay from './screens/MenuOverlay';
import { useState } from 'react';
import { Outlet } from "react-router-dom";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


export default function App() {

  const [menuOpen, setMenuOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  menuOpen ? disableBodyScroll(document) : enableBodyScroll(document);

  return (
    <main>
      <MenuButton
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        videoOpen={videoOpen}
        setVideoOpen={setVideoOpen}
      />
      <MenuOverlay
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        videoOpen={videoOpen}
        setVideoOpen={setVideoOpen}
      />
      <Outlet />
    </main>
  );
}