import Button from "./Button";
import { Link } from "react-router-dom";


export default function AlbumHeader(props) {
  return (
    <div className="album-header">
      <h2 className="album-title">{props.name}</h2>
      <div className="album-buttons-container">
        <div className="album-buttons">
          <a href={props.streamLink}>
            <Button text="Stream" />
          </a>
          <a href={props.downloadLink}>
            <Button text="Download" />
          </a>
          <Link to="/store">
            <Button text="Order" />
          </Link>
        </div>
      </div>
    </div >
  );
}