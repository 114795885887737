import Input from "../components/Input";
import NewsletterButton from "../components/NewsletterButton";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useState, useEffect } from "react"

export default function NewsletterForm({ status, message, onValidated }) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status])

  const clearFields = () => {
    setName('');
    setEmail('');
  }

  return (
    <form className="mc-container">
      <h1 className="mc-label">Name:</h1>
      <Input state={name} changeHandler={setName} />
      <h1 className="mc-label">Email:</h1>
      <Input state={email} changeHandler={setEmail} />
      <NewsletterButton name={name} email={email} text="Sign Up" onValidated={onValidated} />
      {status === "sending" && (
        <div className="mc-message mc__alert mc__alert--sending">
          Sending...
        </div>
      )}
      {status === "error" && (
        <div
          className="mc-message mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="mc-message mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </form>
  )
}