export default function MenuButton({ menuOpen, setMenuOpen, videoOpen }) {

  let className = 'menu-button';
  if (menuOpen) {
    className += ' menu-button-active';
  }
  if (videoOpen) {
    className += ' video-button';
  }

  return (
    <div>
      <button className={className} onClick={() => setMenuOpen(!menuOpen)} />
    </div>
  );
} 