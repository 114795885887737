import VideoBanner from "../components/VideoBanner";
import VideoPlayer from "../components/VideoPlayer";
import VideoSelector from "../components/VideoSelector";
import { useState, useEffect } from "react";
import axios from 'axios';

export default function Video() {
  const [bannerEnabled, setBannerEnabled] = useState(true)
  const [videoSource, setVideoSource] = useState("")
  const [videoOptions, setVideoOptions] = useState([])
  const [listReady, setListReady] = useState(0)

  useEffect(() => {
    axios.get('https://api.rrc.media/api/se-videos?sort=Order:ASC')
      .then((res) => {
        let opts = res.data.data.map((video) => {
          return { value: video.attributes.Link, label: video.attributes.Title }
        })
        setVideoSource(opts[0].value)
        setVideoOptions([...opts])
        setListReady(1)
      })
  }, [])


  return (
    <div>
      <VideoBanner page="video" bannerEnabled={bannerEnabled} />
      <VideoPlayer videoSource={videoSource} setBannerEnabled={setBannerEnabled} />
      <VideoSelector videoOptions={videoOptions} listReady={listReady} setVideoSource={setVideoSource} />
    </div>
  );
}