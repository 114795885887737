export default function NewsletterButton({text, email, name, onValidated}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    name &&
    email.indexOf("@") > -1 &&
    onValidated({
        EMAIL: email,
        MERGE1: name
    });
}

  return (
    <div>
      <button className="mc-button" onClick={(e) => handleSubmit(e)}>{text}</button>
    </div>
  );
}