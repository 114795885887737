import TeamMember from "./TeamMember";

export default function Team() {
  return (
    <div className="team-grid">
      <TeamMember
        title="MANAGEMENT"
        name="Martin Anderson"
        mailto="mailto:martin@theglowmgmt.com"
        email="martin@theglowmgmt.com" />
      <TeamMember
        title="US BOOKING"
        name="Jackie Nalpant"
        mailto="mailto:jnalpant@teamwass.com"
        email="jnalpant@teamwass.com" />
      <TeamMember
        title="EU BOOKING"
        name="Dave Exley"
        mailto="mailto:david.exley@paradigmagency.com"
        email="david.exley@paradigmagency.com" />
      <TeamMember
        title="LICENSING"
        name="Joe Maggini"
        mailto="mailto:joe@hipgnosissongs.com"
        email="joe@hipgnosissongs.com" />
      <TeamMember
        title="PUBLICITY"
        name="Matt Hanks"
        mailto="mailto:mhanks@shorefire.com"
        email="mhanks@shorefire.com" />
    </div>
  );
}