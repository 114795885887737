import MailchimpSubscribe from "react-mailchimp-subscribe";
import NewsletterForm from "./NewsletterForm";

export default function Newsletter() {
  const postUrl = "https://theglowmgmt.us17.list-manage.com/subscribe/post?u=1143ef2ae4c2f267d492eb9cc&id=3184b747b9"

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <NewsletterForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
        />
    </div>
  );
}