
export default function VideoBanner({page, bannerEnabled}) {
  let className = "video-banner"
  if (!bannerEnabled) {
    className += " hidden"
  }
  return (
    <div className={className}>
      <h1>NO RULES SANDY</h1>
      <h1>({page})</h1>
    </div>
  );
}