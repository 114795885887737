export const loadCollection = () => {
  var client = window.ShopifyBuy.buildClient({
    domain: 'the-glow-mgmt.myshopify.com',
    storefrontAccessToken: '9654c220bf50ccccf3cd2e73168b4bef',
  });
  window.ShopifyBuy.UI.onReady(client).then(function (ui) {
    ui.createComponent('collection', {
      id: '437620343059',
      node: document.getElementById('collection-component-1686845033952'),
      moneyFormat: '%24%7B%7Bamount%7D%7D',
      options: {
        "product": {
          "styles": {
            "product": {
              "@media (min-width: 601px)": {
                "max-width": "calc(33.33333% - 30px)",
                "margin-left": "30px",
                "margin-bottom": "50px",
                "width": "calc(33.33333% - 30px)"
              },
              "img": {
                "height": "calc(100% - 15px)",
                "position": "absolute",
                "left": "0",
                "right": "0",
                "top": "0"
              },
              "imgWrapper": {
                "padding-top": "calc(75% + 15px)",
                "position": "relative",
                "height": "0"
              }
            },
            "title": {
              "font-family": "Helvetica, sans-serif",
              "font-size": "16px",
              "color": "000000",
              "font-weight": "400"
            },
            "button": {
              "font-family": "Helvetica, sans-serif",
              "padding": "5px 20px",
              "border": "1.8px solid #000000",
              "background-color": "white",
              "letter-spacing": "-0.02em",
              "color": "#000000",
              ":hover": {
                "color": "#FFFFFF",
                "background-color": "#026F42",
                "transition": "none",
                "-webkit-transition": "none",
              },
              "background-color": "#ffffff",
              ":focus": {
                "background-color": "#026F42",
                "color": "#FFFFFF",
              },
              "border-radius": "45px",
            },
            "quantityInput": {
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px"
            },
            "price": {
              "font-size": "16px",
              "color": "000000"
            },
            "compareAt": {
              "font-size": "14.45px",
              "color": "#d3eedf"
            },
            "unitPrice": {
              "font-size": "14.45px",
              "color": "#d3eedf"
            },
            "description": {
              "font-size": "17px",
              "color": "#666666"
            }
          },
          "buttonDestination": "modal",
          "contents": {
            "options": false
          },
          "text": {
            "button": "View product"
          },
          "googleFonts": [
            "Montserrat"
          ]
        },
        "productSet": {
          "styles": {
            "products": {
              "@media (min-width: 601px)": {
                "margin-left": "-30px"
              }
            }
          }
        },
        "modalProduct": {
          "contents": {
            "img": false,
            "imgWithCarousel": true,
            "button": false,
            "buttonWithQuantity": true
          },
          "styles": {
            "product": {
              "@media (min-width: 601px)": {
                "max-width": "100%",
                "margin-left": "0px",
                "margin-bottom": "0px"
              }
            },
            "button": {
              "font-family": "Helvetica, sans-serif",
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px",
              "color": "#ffffff",
              "border-width": "1.8px",
              "border-style": "solid",
              "background-color": "#026F42",
              ":focus": {
                "background-color": "#a6a6a6",
                "color": "#000000"
              },
              "padding-left": "47px",
              "padding-right": "47px"
            },
            "quantityInput": {
              "font-size": "16px",
              "padding-top": "16px",
              "padding-bottom": "16px"
            },
            "title": {
              "font-family": "Helvetica Neue, sans-serif",
              "font-weight": "bold",
              "font-size": "26px",
              "color": "#4c4c4c"
            },
            "price": {
              "font-family": "Helvetica Neue, sans-serif",
              "font-weight": "normal",
              "font-size": "18px",
              "color": "#4c4c4c"
            },
            "compareAt": {
              "font-family": "Helvetica Neue, sans-serif",
              "font-weight": "normal",
              "font-size": "15.299999999999999px",
              "color": "#4c4c4c"
            },
            "unitPrice": {
              "font-family": "Helvetica Neue, sans-serif",
              "font-weight": "normal",
              "font-size": "15.299999999999999px",
              "color": "#4c4c4c"
            },
            "description": {
              "font-family": "Helvetica Neue, sans-serif",
              "font-weight": "normal",
              "font-size": "14px",
              "color": "#4c4c4c"
            }
          },
          "googleFonts": [
            "Montserrat"
          ]
        },
        "option": {
          "styles": {
            "label": {
              "font-family": "Helvetica, sans-serif",
            },
            "select": {
              "font-family": "Helvetica, sans-serif"
            }
          },
          "googleFonts": [
            "Montserrat"
          ]
        },
        "cart": {
          "styles": {
            "button": {
              "font-family": "Helvetica, sans-serif",
              "padding": "5px 20px",
              "border": "1.8px solid #000000",
              "background-color": "white",
              "letter-spacing": "-0.02em",
              "color": "#000000",
              ":hover": {
                "color": "#FFFFFF",
                "background-color": "#026F42",
                "transition": "none",
                "webkit-transition": "none",
              },
              "background-color": "#ffffff",
              ":focus": {
                "background-color": "#026F42",
                "color": "#FFFFFF"
              },
              "border-radius": "45px",
            },
            "title": {
              "color": "#000000"
            },
            "header": {
              "color": "#000000"
            },
            "lineItems": {
              "color": "#000000"
            },
            "subtotalText": {
              "color": "#000000"
            },
            "subtotal": {
              "color": "#000000"
            },
            "notice": {
              "color": "#000000"
            },
            "currency": {
              "color": "#000000"
            },
            "close": {
              "color": "#000000",
              ":hover": {
                "color": "#000000"
              }
            },
            "empty": {
              "color": "#000000"
            },
            "noteDescription": {
              "color": "#000000"
            },
            "discountText": {
              "color": "#000000"
            },
            "discountIcon": {
              "fill": "#000000"
            },
            "discountAmount": {
              "color": "#000000"
            }
          },
          "text": {
            "notice": "Shipping costs are added at checkout."
          },
          "popup": false,
          "googleFonts": [
            "Montserrat"
          ]
        },
        "toggle": {
          "styles": {
            "toggle": {
              "font-family": "Helvetica, sans-serif",
              "background-color": "#ffffff",
              ":hover": {
                "background-color": "#026F42",
                "transition": "none",
                "-webkit-transition": "none"
              },
              ":focus": {
                "background-color": "#026F42"
              }
            },
            "count": {
              "font-size": "16px",
              "color": "#000000",
              ":hover": {
                "color": "#000000"
              }
            },
            "iconPath": {
              "fill": "#000000"
            }
          },
          "googleFonts": [
            "Montserrat"
          ]
        },
        "lineItem": {
          "styles": {
            "variantTitle": {
              "color": "#000000"
            },
            "title": {
              "color": "#000000"
            },
            "price": {
              "color": "#000000"
            },
            "fullPrice": {
              "color": "#000000"
            },
            "discount": {
              "color": "#000000"
            },
            "discountIcon": {
              "fill": "#000000"
            },
            "quantity": {
              "color": "#000000"
            },
            "quantityIncrement": {
              "color": "#000000",
              "border-color": "#000000"
            },
            "quantityDecrement": {
              "color": "#000000",
              "border-color": "#000000"
            },
            "quantityInput": {
              "color": "#000000",
              "border-color": "#000000"
            }
          }
        }
      },
    });
  });
}