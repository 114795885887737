

export default function HomeBanner(props) {
  return (
    <div class="banner home-banner">
      <h1>NO RULES SANDY</h1>
      <h1>({props.page})</h1>
      <h1>SYLVAN ESSO</h1>
    </div>
  );
}