import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from "react";

let last = -1

export const ImageRevealer = ({images}) => {
  let className = "home-image"
  let classes = []
  const imgRefs = useRef([])
  const [zIndex, setZIndex] = useState(1)
  const [revealed, setRevealed] = useState(-1)

  const handleClick = (key) => {
    imgRefs.current[key].style.zIndex = zIndex;
    setZIndex(zIndex + 2)
  }

  useEffect(() => {
    const revealer = setInterval(() => {
        setRevealed(revealed + 1)
    }, 1500)
    return () => clearInterval(revealer)
  })

  for (let i = 0; i < images.length; i++) {
    if (i > revealed) {
      classes.push(className + " hidden")
    } else {
      if (last == (i-1)) {
        imgRefs.current[i].style.zIndex = zIndex - 1
        imgRefs.current[i].style.paddingLeft = Math.floor(Math.random() * 10).toString() + "vw";
        imgRefs.current[i].style.paddingRight = Math.floor(Math.random() * 10).toString() + "vw";
        imgRefs.current[i].style.paddingTop = Math.floor(Math.random() * 10).toString() + "vh";
        imgRefs.current[i].style.paddingBottom = Math.floor(Math.random() * 10).toString() + "vh";
      }
      classes.push(className)
    }
  }

  last = revealed

  let output = images.map((img, idx) =>
    <motion.img
      drag
      key={idx}
      ref={(element) => { imgRefs.current[idx] = element }}
      className={classes[idx]}
      alt=""
      src={img}
      onMouseDown={() => { handleClick(idx) }}>
    </motion.img>)

  return (
    <div className="home-image-container" >
      {output}
    </div>
  );
}