export default function Socials() {
  return (
    <div className="social-container">
      <ul id="social">
        <li>
          <a href="https://found.ee/SE_instagram">
            <i class="fab fa-instagram"></i>
          </a>
        </li>
        <li>
          <a href="https://found.ee/SE_twitter">
            <i class="fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://found.ee/SE_facebook">
            <i class="fab fa-facebook"></i>
          </a>
        </li>
        <li>
          <a href="https://found.ee/SE_YouTube">
            <i class="fab fa-youtube"></i>
          </a>
        </li>
        <li>
          <a href="https://found.ee/SE_soundcloud">
            <i class="fab fa-soundcloud"></i>
          </a>
        </li>
      </ul>

    </div>
  )
}