import Select from "react-select";


const formatOptionLabel = ({ label }) => (
  <div style={{ display: "flex" }}>
    <div>{label}</div>
  </div>
);

export default function VideoSelector({ videoOptions, listReady, setVideoSource }) {
  
  const handleReactSelectChange = (selectedOption) => {
    setVideoSource(selectedOption.value)
  }

  return (
    <div key={listReady} className="select-container">
      <Select
        defaultValue={videoOptions[0]}
        isSearchable={false}
        formatOptionLabel={formatOptionLabel}
        options={videoOptions}
        className="select"
        onChange={handleReactSelectChange}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#white',
            primary: '#026F42',
            primary50: '#a6a6a6',
            neutral0: 'black',
            neutral20: 'white',
            neutral30: 'white',
            neutral40: 'white',
            neutral50: 'white',
            neutral60: 'white',
            neutral70: 'white',
            neutral80: 'white',
            neutral90: 'black',
          },
        })}
      />
    </div>
  );
}

