import Track from "./Track";
import { getAlbums } from "../albumData";

export default function TrackList(props) {

  let albums = getAlbums();

  let track = [];

  albums.map((album) => (
    album.name.includes(`${props.name}`) &&
    track.push(album)
  ))

  return (
    <div className="track-list">
      {
        track[0].tracks.map((song) => (
          <Track trackName={song.trackName} length={song.length} />
        ))
      }
    </div>
  );
}