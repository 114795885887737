import HomeBanner from "../components/HomeBanner";
import { ImageRevealer } from "../components/ImageRevealer";
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function Home() {
  const [images, setImages] = useState([])
  const image_base = 'https://api.rrc.media'

  useEffect(() => {
    axios.get('https://api.rrc.media/api/se-images?populate=Image&sort=Order:ASC')
        .then((res) => {
          let urls = res.data.data.map((img) => {
            return image_base + img.attributes.Image.data.attributes.url
          })
          setImages(urls)
        })
  }, [])

  return (
    <main>
      <HomeBanner page="website" />
      <ImageRevealer images={images}/>
    </main>
  );
}