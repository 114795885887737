import AlbumHeader from "./AlbumHeader";
import TrackList from "./TrackList";

export default function AlbumInfo(props) {
  return (
    <div className="album-info">
      <AlbumHeader name={props.name} streamLink={props.streamLink} downloadLink={props.downloadLink} />
      <TrackList name={props.name} />
    </div>
  );
}