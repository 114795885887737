import Banner from "../components/Banner";
import Newsletter from "../components/Newsletter";
import Team from "../components/Team";
import Socials from "../components/Socials";
import { useEffect, useRef } from "react";
import { isMobile } from 'react-device-detect';

const scrollToRef = (ref) => window.scrollTo({
  top: ref.current.offsetTop,
  left: 0,
  behavior: 'smooth'
})


export default function Contact() {

  const myRef = useRef(null)
  useEffect(() => {
    const timer = setTimeout(() => scrollToRef(myRef), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Banner page="contact" />
      <div className="scroll-container-contact" ref={isMobile ? null : myRef}>
        <Newsletter />
      </div>
      <div class="team-social-container">
        <Team />
        <Socials />
      </div>
    </div>
  );
}