export default function Input({state, changeHandler}) {
  const handleChange = (event) => {
    changeHandler(event.target.value)
  }

  return (
    <div>
      <input value={state} onChange={handleChange} type="text" className="mc-input"></input>
    </div>
  );
}